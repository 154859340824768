import { useState } from 'react';

import { shallowEqual } from '@/utils/shallowEqual';

// eslint-disable-next-line no-restricted-imports
import { useBranchChangeSubscription } from '@/domains/branches/api/useBranchChangeSubscription';
import {
  useUserInfoSubscription,
  useSiteStatusSubscription,
} from '@knapsack/hasura-gql-client/apollo';
import { useAppCtxSelector, useAppStateMatches, sendAppEvent } from '../xstate';

export function useAppGqlSubscriptions() {
  useBranchChangeSubscription();

  const [lastRoleId, setLastRoleId] = useState('');
  const userId = useAppCtxSelector(({ user }) => user?.userId);
  const userInfo = useAppCtxSelector(({ user }) => user?.info ?? {});
  const siteId = useAppCtxSelector(({ site }) => site?.meta.siteId);
  const isUserFullyLoaded = useAppStateMatches('user.loggedIn.loaded.userInfo');

  useUserInfoSubscription({
    variables: {
      userId,
    },
    skip: !isUserFullyLoaded,
    onData({ data: { data } }) {
      if (!data?.user) return;
      const {
        displayName,
        firstName,
        lastName,
        profilePic,
        sites,
        responsibilityId,
        dateCreated,
      } = data.user;
      const newInfo = {
        displayName,
        firstName,
        lastName,
        profilePic,
        responsibilityId,
        dateCreated,
      };

      if (!shallowEqual(userInfo, newInfo)) {
        sendAppEvent({
          type: 'user.infoChanged',
          info: newInfo,
        });
      }

      // roleId of current site ONLY
      const roleId = sites?.find(
        ({ siteId: siteId_ }) => siteId_ === siteId,
      )?.roleId;

      if (!roleId) {
        return;
      }

      // Cache roleId lookup
      if (lastRoleId === '' && roleId) {
        setLastRoleId(roleId);
        return;
      }

      // Now that there is a sites state, compare against incoming sites
      if (lastRoleId !== roleId) {
        setLastRoleId(roleId);

        // Warn then logout the user so claims are reregistered on next login
        sendAppEvent({
          type: 'app.sendUserMsg',
          msg: {
            type: 'warning',
            message: 'Your role has changed. Signing you out.',
            autoClose: 10000,
            onClose: () => sendAppEvent({ type: 'user.signOut' }),
          },
        });
      }
    },
  });

  const siteStatus = useAppCtxSelector(({ site }) => site?.meta.status);

  useSiteStatusSubscription({
    variables: {
      siteId,
    },
    skip: !siteId,
    onData({ data: { data } }) {
      if (!data) return;
      if (siteStatus === data.site.status) return;
      sendAppEvent({ type: 'site.statusChanged', status: data.site.status });
    },
  });
}
