import { useRef } from 'react';
import {
  sendAppClientDataEvent,
  useAppCtxSelector,
  useInstanceId,
} from '@/core';
import { isPatches, KsRemoteChange } from '@/types';
import { useDataChangesSubscription } from '@knapsack/hasura-gql-client/apollo';
import { createToast } from '@knapsack/toby';

export function useBranchChangeSubscription() {
  const hadInitialDataChange = useRef(false);
  const instanceId = useInstanceId();
  const userId = useAppCtxSelector(({ user }) => user?.userId);

  useDataChangesSubscription({
    variables: {
      instanceId,
      userId,
    },
    skip: !instanceId && !userId,
    onData({ data: { loading, data, error } }) {
      if (loading) return;
      if (error) {
        const msg = `An unexpected error occured while saving data, please reload page. If you continue to have issues, log out and log back in.`;
        console.error(`useDataChangesSubscription() ${msg}`, error);
        createToast({
          autoClose: false,
          type: 'error',
          message: msg,
          action: {
            label: 'Reload',
            onTrigger: () => window.location.reload(),
          },
        });
        return;
      }
      if (!hadInitialDataChange.current) {
        // console.log(`enableDataSubscription on`);
        hadInitialDataChange.current = true;
        return;
      }
      const changes: KsRemoteChange[] = data.dataChanges.flatMap(
        ({ id, patches, date }) => {
          if (!isPatches(patches)) return [];
          return [
            {
              id,
              patches,
              date,
            },
          ];
        },
      );

      if (changes.length === 0) return;
      sendAppClientDataEvent({
        type: 'appClientData.externallyChanged',
        subtype: 'changes',
        changes,
      });
    },
  });
}
