import { sendUiEvent, useAppCtxSelector, useAppStateMatches } from '@/core';
import { useEffect } from 'react';
import { ProfileForm } from '../components/profile-form';

export const useProfileFormInterceptor = () => {
  const responsibility = useAppCtxSelector(
    (ctx) => ctx.user?.info?.responsibilityId,
  );
  const hasUserAndSite = useAppStateMatches(
    'usersAndSite.siteLoaded.loggedInUser.allInfo',
  );

  useEffect(() => {
    if (!hasUserAndSite) return;
    if (responsibility) return;
    sendUiEvent([
      {
        type: 'modal.setContent',
        modal: {
          body: <ProfileForm />,
          title: 'Welcome Back',
          subtitle: "Let's make sure your profile is up to date.",
          testId: 'profile-form-interceptor',
          size: 'wide',
        },
      },
      'modal.triggerOpen',
    ]);
  }, [hasUserAndSite, responsibility]);
};
